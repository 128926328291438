.pagination {
  margin-top:20px;
  gap: 5px;
  .page-item {
    .page-link {
      border-radius: 8px;
      margin-left: 0;
      padding: 4px 10px;
      font-size: 14px;
      color: #838287;
      border: none;   
      max-width: 28px;
      max-height: 28px;
      line-height: 20px;
      .visually-hidden {
        display: none;
      }
      &:hover {
        background-color: #2AA8ED;
        color: #fff;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    &:not(.active) {
      .page-link span{
        font-size: 20px;
        line-height: 1;
      }
    }
    a {
      color: #2AA8ED;
    }
    &.active {
      span {
        background: #2AA8ED;
        color: #FFFFFF;
        border: #2AA8ED solid 1px;
      }
    }
  }
}

.container-btn-login {
  display: flex;
  justify-content: space-between;
  .btn-sign-in {
    padding: 10 !important;
    background-color: #84c24e !important;
    border-radius: 5px !important;
    color: white;
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg: white;
    --bs-btn-hover-border-color: black;
  }
  .error-text {
    font-size: 15px;
    font-weight: bold;
  }
}
.error-text {
  font-weight: bold;
}
.AI-container {
  .request-table-data {
    width: 98%;
    margin: 0 20px;
    background: #f7f7f7 !important;
    .request-user-info {
      margin-bottom: 10px;
      justify-content: space-between;
      align-items: center;
      p.ui-text {
        font-weight: 700;
        font-size: 16px;
        padding-right: 20px;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 40%;
        display: inline-block;
      }
    }
    .table-cover-form {
      width: 500px;
    }
  }
  .resume-detail {
    span {
      word-wrap: break-word;
      white-space: normal;
    }
  }
  .header-request-page {
    .btn-view-resume {
      width: 130px;
      height: 40px;
      border-radius: 4px;
      background: #3783FC;
      color: #FFFFFF;
      padding: 2px 3px;
    }
  }
  .footer-detail {
    width: 98%;
    margin: 0 20px;
  }
  .detail-p2 {
    textarea.form-control {
      min-height: 130px;
    }
  }
  .footer-detail {
    .request {
      background-color: #3783FC;
    }
    .denied {
      background-color: #FF003D;
    }
    .sent {
      background-color: #91CF7B;
    }
    .working {
      background-color: #FCAD37;
    }
    button {
      width: 120px;
      height: 45px;
      border-radius: 4px;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.dashboard-container, .AI-container {
  .dashboard-title {
    h4{
      font-weight: 700;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      color: #909090;
    }
  }
  .request-table-data {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 15px;
    .request-table-name {
      color: #65A400;
      font-weight: 600;
    }
    .request-status {
      padding: 2px 3px;
      gap: 10px;
      border-radius: 5px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 12px;
      &.request {
        background-color: #3783FC;
      }
      &.denied {
        background-color: #FF003D;
      }
      &.sent {
        background-color: #91CF7B;
      }
      &.working {
        background-color: #FCAD37;
      }
    }
  }
  .request-page-container {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .detail-p1 {
    p {
      margin-bottom: 10px;
    }
  }
  .detail-p2 {
    input {
      width: 100%;
    }
  }
}
.login-container, .dashboard-container {
  height: calc(100vh - 190px);
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 420px;
  }
}
.resume-user-wrapper {
  .resume-user-content {
    .resume-user-header-control {
      padding: 12px 15px;
      .control-filter {
        display: flex;
        flex-direction: column;
        .page-title {
          .item-control {
            .title {
              font-size: 27px;
              color: #181818;
              padding-bottom: 10px;
            }
          }
        }
        .page-toolbar {
          display: flex;
          row-gap: 10px;
          align-items: end;
          height: auto;
          color: #818181;
          &.studio-table {
            height: unset !important;
            flex-wrap: wrap;
            min-height: 50px;
          }
          .reset-item {
            .reset-button {
              font-size: 16px;
              font-weight: bold;
              background-color: white;
              color: red;
              border: 0;
              padding-left: 0;
              border: #EEEFF1 1px solid;
              padding:5px 10px 4px;
              border-radius: 8px;
            }
          }
          .item-control {
            height: 100%;
            margin-right: 8px;
            .css-5hxdq6-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline {
              border-color: #EEEFF1;
            }
            .MuiInputBase-root {
              line-height: normal;
             
              .MuiSelect-select {
                min-height: unset !important;
                font-size: 14px;
                padding: 10px 14px 8px;
              }
              fieldset {
                border: #EEEFF1 1px solid;
                border-radius: 10px;
              }
            }
            .form-control {
              height: 100% !important;
              min-height: 36px !important;
              border: 1px solid #EEEFF1 !important;
              padding: 0 12px !important;
              font-size: 14px;
              color: #393939;
            }
            .option, .search-option, .detail-option{
              width: 200px;
            }
            .search-input {
              width: 266px;
              font-size: 14px;
              padding-left: 31px !important;
            }
          }
        }
      }
      .item-control {
        display: flex;
        flex-direction: column;
        select {
          width: auto;
          height: 35px !important;
          min-height: 35px !important;
          padding-bottom: 0;
          padding-top: 0;
        }
        select:focus, input:focus {
          border-color: #9D68FA;
        }
        .search-icon {
          position: absolute;
          left: 10px;
          top: 8px;
          border: none;
          padding: 0;
          &:active {
            background: none !important;
          }
        }
      }
    }
    .result-search {
      font-size: 14px;
      font-weight: normal;
      color: #393939;
      span {
        font-weight: 700;
      }
    }
  }
}
.header-control {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  .control-filter {
    display: flex;
    flex-direction: column;
    .item-control {
      .css-5hxdq6-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #CAADFF;
      }
      margin-right: 25px;
    }
  }
  .ba-control-wrap {
    display: flex;
    align-items: end;
    margin-top: 10px;
    .trans {
      opacity: 0 !important;
    }
    &.user-control {
      .search-icon {
        top: 34px !important;
      }
    }
  }
  .control-search {
    input {
      width: 500px;
    }
  }
  .item-control {
    display: flex;
    flex-direction: column;
    &.ai-control {
      align-items: center;
      flex-direction: row;
    }
    fieldset {
      border: 1px solid #E0DEE1;
      border-radius: 8px;
    }
    select {
      width: auto;
      height: 35px !important;
      min-height: 35px !important;
      padding-bottom: 0;
      padding-top: 0;
    }
    select:focus, input:focus {
      border-color: #9D68FA;
    }
    input {
      width: 350px;
      height: 40px !important;
      min-height: 40px !important;
    }
    @media screen and (max-width: 1600px) {
      /* CSS styles for screens wider than 768px */
      input {
        width: 400px;
      }
    }
    .search-icon {
      position: absolute;
      right: 12px;
      top: 10px;
      border: none;
      padding: 0;
    }
  }
  .btn-dowload, .btn-delete {
    border-radius: 8px !important;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    background-color: #9D68FA !important;
  }
  .btn-delete {
    color: #000000 !important;
    background-color: white !important;

  }
}
.search-result {
  color: #393939;
  font-size: 16px;
}
.btn-download-container {
  padding-top: 20px;
}
.ms-title {
  font-size: 14px;
  padding-bottom: 3px;
  color: #393939;
}
.user-table-data {
  padding: 0 15px;
  .MuiTableContainer-root {
    max-height: 600px;
  }
  @media screen and (max-width: 1600px) {
    /* CSS styles for screens wider than 768px */
    .MuiTableContainer-root {
      max-height: 400px;
    }
  }
  @media screen and (min-width: 1600px) and (max-width: 1800px) {
    /* CSS styles for screens wider than 768px */
    .MuiTableContainer-root {
      max-height: 500px;
    }
  }

  .css-hv6h5i-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-hv6h5i-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate, .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #9D68FA !important;
  }
  
  .Mui-focused .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #9D68FA !important;
  }
  .user-status {
    padding: 1px 6px;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 12px;
    border: 1px solid #fff;
    &.active {
      color: #075A39;
      border: 1px solid #C7F4D3;
      background: #DDF9E4;
    }
    &.dormant {
      border: 1px solid #C3EDF9;
      background: #DAF4FC;
      color: #0A5A70;
    }
    &.stopped {
      color: #5C5E63;
      border: 1px solid #EEEFF1;
      background: #F4F5F6;
    }
    &.withdrawal {
      border: 1px solid #FFEBAD;
      background: #FFF3CC;
      color: #705500;
    }
    &.deleted {
      background-color: #FFEBEB;
      color: #772322;
      border:1px solid #FFDCDB;
    }
    &.request {
      border: 1px solid #E8DDFE;
      background: #F5EEFF;
      color: #4711BB;
    }
    &.denied {
      border: 1px solid #FFDCDB;
      background: #FFEBEB;
      color:#772322;
    }
    &.sent {
      border: 1px solid #C7F4D3;
      color: #075A39;
      background: #DDF9E4;
    }
    &.working {
      border: 1px solid #D6E5FF;
      background: #E5EEFF;
      color: #183C81;
    }
  }
  .user-detail-info-yes {
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #0DCAF0;
    color: #FFFFFF;
    text-decoration: none;
  }
  .user-detail-info-no {
    text-decoration: none;
    color: black;

  }
  .user-detail-info-no {
    font-weight: bold;
  }
}

.header-templates {
  display: flex;
  justify-content: space-between;
  .sort-toggle {
    border-left: 1px black solid;
    margin-left: 15px;
    padding-left: 10px;
    cursor: pointer;
  }
}
.body-templates {
  .item-templates-in-list {
    img {
      display: flex;
      align-items: center;
    }
    .checkbox-item {
      span {
        padding: 0;
        position: absolute;
        top: 5px;
        left: 7px;
        .css-i4bv87-MuiSvgIcon-root {
          width: 16px;
          height: 16px;
        }
      }
    }
    .status-templates {
      background: #5DBBEA;
      border-radius: 15px;
      height: 16px;
      width: 16px;
      display: block;
      position: absolute;
      bottom: 6px;
      right: 8px;
    }
    &.default {
      background-color: #F2F2F2;
    }
    &.custom-design {
      background-color: #FEEDDE;
    }
    &.custom-digitalized {
      background-color: #F0E4FF;
    }
    &.trash {
      background-color: #8C8C8C;
    }
  }
}
.add-edit-template {
  background-color: #F2F2F2;
  min-height: calc(100vh - 170px);
  .preview-img-template {
    img {
      width: 100%;
      padding: 0 50px;
      height: 600px;
    }
  }
  .footer-upload {
    padding-bottom: 10px;
  }
  .form-edit-template, .button-top-edit {
    .save-btn {
      background-color: #925AFB;
      color: #FFFFFF;
    }
    input, select {
      max-height: 5px;
      background-color: #FFFFFF;
    }
    button {
      border-color: #CED4DA;
      color: black;
      background-color: #FFFFFF;
    }
    .css-q8hpuo-MuiFormControl-root {
      background-color: #FFFFFF;
    }
    
    .css-1k23hlb-MuiButtonBase-root-MuiButton-root:hover {
      border-color: black;
    }
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, .css-5hxdq6-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #CAADFF;
    }
    .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #CAADFF;
    }
  }
  .form-edit-template {
    padding-right: 60px;
    .status-templates {
      background: #5DBBEA;
      border-radius: 15px;
      height: 16px;
      width: 16px;
      display: inline-block;
    }
    .info-inuse {
      width: 75%;
      align-items: center;
      color: #C9C9C9
    }
    @media screen and (max-width: 1600px) {
      /* CSS styles for screens wider than 768px */
      .info-inuse {
        width: 90%;
      }
      button {
        font-size: 11px;
      }
    }
    @media screen and (min-width: 1600px) and (max-width: 1800px) {
      /* CSS styles for screens wider than 768px */
      .info-inuse {
        width: 80%;
      }
    }
  }
  .css-1laqsz7-MuiInputAdornment-root {
    span {
      cursor: pointer;
    }
  }
}

.tree-folder {
  input {
    max-height: 5px;
  }
}

select {
  cursor: pointer;
}

.custom-cursor {
  cursor: wait; /* Replace 'wait' with the cursor of your choice (e.g., 'pointer', 'progress', 'url(path/to/cursor.png)', etc.) */
}

//Main Table Style
.main-table {
  padding: 10px 0 0 0;
  .pagination {
      margin-top: 0;
  }
  .MuiPaper-root,
  .MuiTableRow-head {
      border: none;
      box-shadow: none !important;
      background: none;
  }
  .MuiTableContainer-root {
      max-height: unset !important;
      thead {
          th {
              background:#F9F9FA;   
              border: #F3F2F3 1px solid;
              border-top: none;
              padding: 8px 12px;
              &:first-child {
                  max-width: 50px;
              }
          }
          .Mui-TableHeadCell-Content {
              justify-content: flex-start;
              .Mui-TableHeadCell-Content-Labels {
                  padding: 0 !important;
              }
              .Mui-TableHeadCell-Content-Wrapper {
                  color: #181818;
                  font-size: 14px;
                  font-weight: 500;
                  white-space: nowrap;
              }

          }
    }
    .MuiTableBody-root {
        tr.MuiTableRow-root{
            &:nth-of-type(odd) {
                background-color: #fff;
            }
            &:nth-child(even) {
                background: #F9F9FA;
            }
        }
        td {
            
            border: #F3F2F3 1px solid;
            border-top: none;
            border-left: none;
            padding:5px 12px;
            color: #393939;
            background: none !important;
            fieldset {
              border-radius: 10px;
              border: 1px solid #EEEFF1;
            }
        }
        tr:last-child{
            td {
                border-bottom: none;
            }
        }
    }
  }
}
.template-container {
  margin-top: 15px;
}
.h-title {
  font-size: 1.7rem;
  padding: 20px;
  color: #181818;
  font-weight: bold;
}